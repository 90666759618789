import * as React from "react"
import logo from "../images/logo.png"
import video1 from "../images/video1.mp4"
import quest2 from "../images/quest2.png"
import favicon from "../images/col.png"
import "../styles/index.css"

const pageStyles = {
  padding: 0,
  margin:0,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  textAlign: "center"
}

const recorridos = {
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  color: "gray"
}

const logoScreen = {

   width: 250,

}

const videoScreen = {
 
   width: "100%"
}


const IndexPage = () => {
  return (
    <main style={pageStyles}>
      
      <img style={logoScreen} src={logo} alt="Logo" />

      <video style={videoScreen} autoplay="autoplay" loop muted>
        <source src={video1} type="video/mp4" />
      </video>

      <p style={recorridos}>Recorridos Virtuales 360°</p>

      <img style={logoScreen} src={quest2} alt="Logo" />

      <p style={recorridos}>WhatsApp: <a href="https://wa.me/573504609111?text=Me%20interesa%20el%20recorrido%20virtual">+573504609111</a></p>
  

      <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="40" zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="40" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="id1"><path d="M 3.386719 6.554688 L 26.613281 6.554688 L 26.613281 15 L 3.386719 15 Z M 3.386719 6.554688 " clip-rule="nonzero"/></clipPath><clipPath id="id2"><path d="M 3.386719 14 L 26.613281 14 L 26.613281 20 L 3.386719 20 Z M 3.386719 14 " clip-rule="nonzero"/></clipPath><clipPath id="id3"><path d="M 3.386719 19 L 26.613281 19 L 26.613281 23.246094 L 3.386719 23.246094 Z M 3.386719 19 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#id1)"><path fill="rgb(98.429871%, 81.959534%, 8.628845%)" d="M 24.027344 6.554688 L 5.972656 6.554688 C 4.550781 6.554688 3.394531 7.703125 3.394531 9.121094 L 3.394531 14.902344 L 26.605469 14.902344 L 26.605469 9.121094 C 26.605469 7.703125 25.449219 6.554688 24.027344 6.554688 Z M 24.027344 6.554688 " fill-opacity="1" fill-rule="nonzero"/></g><g clip-path="url(#id2)"><path fill="rgb(13.328552%, 25.099182%, 54.899597%)" d="M 3.394531 14.902344 L 26.605469 14.902344 L 26.605469 19.394531 L 3.394531 19.394531 Z M 3.394531 14.902344 " fill-opacity="1" fill-rule="nonzero"/></g><g clip-path="url(#id3)"><path fill="rgb(80.778503%, 12.548828%, 15.689087%)" d="M 3.394531 20.679688 C 3.394531 22.097656 4.550781 23.246094 5.972656 23.246094 L 24.027344 23.246094 C 25.449219 23.246094 26.605469 22.097656 26.605469 20.679688 L 26.605469 19.394531 L 3.394531 19.394531 Z M 3.394531 20.679688 " fill-opacity="1" fill-rule="nonzero"/></g></svg>


    </main>
  )
}

export default IndexPage

export const Head = () => (
  <>
    <title>Luxurio 360</title>
    <meta name="Recorridos Virtuales 360" />
    <link rel="icon" type="image/x-icon" href={favicon} />
  </>
  )